import { template as template_810065e6bca048e1abfbbaf8f757b9fe } from "@ember/template-compiler";
const FKText = template_810065e6bca048e1abfbbaf8f757b9fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
