import { template as template_7077e61f8fba4dcb9dd343a9348997fd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7077e61f8fba4dcb9dd343a9348997fd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
